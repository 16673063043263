<template>
  <Loader v-if="isLoading" />

  <v-card
    v-else-if="canSendRequestForPin"
    :color="workspaceLayerColor"
    class="mx-auto py-10"
    width="100%"
    max-width="45rem"
  >
    <v-card-title class="px-10 px-md-15">
      <h2 class="headline font-weight-black secondary--text mx-auto mb-2">
        {{ $t('verification.sms') }}
      </h2>
    </v-card-title>

    <InfoBox :message="$t('verification.sms.info')" />

    <v-card-text class="px-10 px-md-15">
      <v-row align="center" justify="space-between">
        <v-col cols="auto">
          <SPhoneNumberInput
            v-model="phoneNumber"
            :disabled="isPhoneNumberChangeDisabled"
            autofocus
            required
            @on-validity-change="phoneNumberValid = $event"
          />
        </v-col>

        <v-col>
          <v-btn
            color="primary"
            max-width="12rem"
            width="100%"
            :loading="isSendingRequest"
            :disabled="!isMobileValid"
            :block="$vuetify.breakpoint.mobile"
            x-large
            style="color: #ffffff"
            @click="onVerifyRequest"
          >
            {{ $t('general.send') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <AlertMessage v-model="error" />
  </v-card>

  <v-card
    v-else
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="45rem"
  >
    <v-card-title class="px-10 px-md-15">
      <h2 class="headline font-weight-black secondary--text mx-auto mb-2">
        {{ $t('two_factor_auth.enter_pin_heading_heading') }}
        ({{ formattedTimeLeft }})
      </h2>
    </v-card-title>

    <InfoBox :message="$t('account.add_pin')" />

    <v-card-text>
      <InputPIN ref="inputPin" v-model="smsPin" class="mb-0 pb-5" @confirmPin="onSendPin" />
    </v-card-text>

    <v-card-text class="pb-10">
      <v-btn
        color="primary"
        max-width="12rem"
        width="100%"
        :loading="isSendingRequest"
        :disabled="smsPin.length === 0"
        :block="$vuetify.breakpoint.mobile"
        x-large
        style="color: #ffffff"
        @click="onSendPin"
      >
        {{ $t('general.send') }}
      </v-btn>
    </v-card-text>

    <AlertMessage v-model="error" :message="errorMessage" />
  </v-card>
</template>

<script>
import { i18n } from '@/plugins/i18n';
import { mapGetters } from 'vuex';
import { authorizedRequest } from '@/services/ApiService';
import { authSmsSeen } from '@verification/services/logService';
import AlertMessage from '@verification/components/AlertMessage';
import Loader from '@/components/Loader';
import InfoBox from '@verification/components/InfoBox';
import SPhoneNumberInput from '@/common/components/SPhoneNumberInput';
import InputPIN from '@/components/InputPIN';
import { getErrorMessage } from '@/common/variables/errors';

export default {
  name: 'Sms',
  components: {
    InputPIN,
    SPhoneNumberInput,
    InfoBox,
    Loader,
    AlertMessage,
  },
  data() {
    return {
      error: false,
      errorMessage: i18n.t('general.error'),
      loaded: false,
      phoneNumber: '',
      phoneNumberValid: false,
      smsPin: '',
      submitted: false,
      timeOfWaitingToSignOnMobile: 0,
      waitingChecker: null,
    };
  },
  computed: {
    ...mapGetters({
      authsSms: 'authsSms/sms',
      workspaceLayerColor: 'auths/workspaceLayerColor',
    }),
    isPhoneNumberChangeDisabled() {
      return 'undefined' !== typeof this.authsSms?.mobile;
    },
    canSendRequestForPin() {
      return !this.isWaitingForPin;
    },
    isLoading() {
      return !this.loaded;
    },
    isMobileValid() {
      return this.phoneNumberValid;
    },
    isSendingRequest() {
      return this.submitted;
    },
    isWaitingForPin() {
      return this.waitingChecker !== null;
    },
    formattedTimeLeft() {
      const timeLeft = this.timeOfWaitingToSignOnMobile;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
  },
  async mounted() {
    this.phoneNumber = this.authsSms.mobile || '';

    await authSmsSeen({ authToken: this.$route.params.authToken });

    setTimeout(() => {
      this.loaded = true;
    }, 800);
  },
  methods: {
    onVerifyRequest() {
      if (!this.isMobileValid) {
        return this.$notification.error(this.$t('contract.errors.mobile'));
      }

      this.submitted = true;
      this.timeOfWaitingToSignOnMobile = 10 * 60;

      authorizedRequest({
        authorization: 'ApiTokenAuth',
        authToken: this.$route.params.authToken,
        method: 'POST',
        endpoint: '/api/v1/auth/sms/send',
        data: { mobile: this.phoneNumber },
      })
        .then(() => {
          this.setWaitingForPin();
        })
        .catch((err) => {
          this.error = true;
          this.errorMessage = getErrorMessage({
            errorCode: err.errorCode,
          });
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    onSendPin() {
      this.submitted = true;

      authorizedRequest({
        authorization: 'ApiTokenAuth',
        authToken: this.$route.params.authToken,
        method: 'PATCH',
        endpoint: '/api/v1/auth/sms',
        data: {
          pin: this.smsPin,
          mobile: this.phoneNumber,
        },
      })
        .then(() => {
          return this.$router.push({ name: 'authentication-identity' });
        })
        .catch((err) => {
          this.error = true;
          this.errorMessage = getErrorMessage({
            errorCode: err.errorCode,
          });
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    setWaitingForPin() {
      this.waitingChecker = setInterval(() => {
        if (0 === this.timeOfWaitingToSignOnMobile) {
          this.closeRequestToSignOnPhone();
        } else {
          this.timeOfWaitingToSignOnMobile -= 1;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
